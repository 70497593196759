h1 {
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h3 {
  font-family: "co-headline", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.apexcharts-text tspan {
  font-family: "futura-pt", sans-serif !important;
}

text {
  font-family: "co-headline", sans-serif !important;
  fill: black !important;
}

.running {
  background-color: #90C63D;
}

.idle {
  background-color: #FFAD1F;
}

.pause {
  background-color: #0073ea;
}

.off {
  background-color: #EC1E24;
}

.none {
  background-color: #7e757e;
}

.ant-modal-content {
  width: fit-content !important
}

/* .App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
} */

.App-header {
  background: rgb(24, 6, 8);
  background: linear-gradient(93deg, rgb(11, 6, 24) 0%, rgb(19, 32, 148) 83%, rgb(24, 93, 203) 100%);
  max-width: 100%;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}

.subheader {
  background-color: black;
  height: 3vh;
  display: flex;
  align-items: center;
}

.statusHeadline {
  color: lightgrey;
  padding: 5px 15px;
  font-size: 1.5vh;
}

.title {
  color: white;
  font-size: 30pt;
}

.grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
}

.img-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: space-between;
  padding: 0;
  background-image: url("../src/assets/PersonaMachines.png");
  background-size: 110%;
  background-position: -3.8vw;
}

.img {
  border: 1px solid rgb(0, 115, 234);
  height: 84vh;
  width: 20%;
}

.img h1 {
  text-align: center;
  color: white;
  background-color: #000000;
  padding: 5px;
}

.img:hover {
  background-color: rgba(90, 214, 255, 0.15);
  border: 1px solid rgb(101, 193, 255);
  cursor: pointer;
}

.assembly-img {
  background-color: rgba(0, 0, 0, 0.5);
}

.assembly-img:hover {
  background-color: rgba(28, 68, 81, 0.5);
}

.status-scroll {
  max-height: 86vh;
  /* 86 viewheight because header is 10vh, subheader is 3vh, and 1vh for gaps/padding */
  overflow-y: scroll;
  padding-top: 20px;
}

.statusTable {
  margin: 0px;
}

.statusTable * {
  font-size: 10pt;
}

.ant-table-cell:nth-child(2){
  font-size: 13pt;
  font-weight: 700;
  text-align: left;
}

.ant-table-cell:nth-child(odd){
  font-size: 13pt;
  font-weight: 700;
  text-align: left;
}

.ant-table-cell {
  border-bottom: 1px solid #c6c6c6 !important;
}

.App-logo {
  height: 100%;
  margin-right: 100px;
}

.ant-progress-inner {
  border-radius: 5px !important;
  box-shadow: 3px 5px 5px 2px rgba(144, 144, 144, 0.1);
  border: #595959 1px solid;
}

.indc {
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50px;
  width: auto;
  text-align: center;
  box-shadow: 3px 5px 5px 2px rgba(0, 0, 0, 0.2);

  color: rgba(0, 0, 0, 1);
  font-size: 15pt;
  font-weight: 700;
  cursor: pointer;
  border: 2px white solid;
}

.indc:hover {
  border: 2px cyan solid;
}

.indc-1 {
  padding: 50px 50px 0px 50px;
}

.legend-container {
  display: flex;
  width: 50%;
  margin: auto;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  gap: 20px;
}

.legend-item {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 10px;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.status-table-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2px;
}

.status-table-container p {
  padding: 5px;
  text-align: right;
}


.status-table-container span {
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 250px;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: center;
}

.program-status {
  background-color: #d1d1d1;
}

.program-status-none {
  background-color: #7e757e;
  color: white;
}

.time-status {
  background-color: #90C63D;
  color: #000000;
}

.idle-time {
  background-color: #FFAD1F;
  color: #000000;
}

.off-time {
  background-color: #EC1E24;
  color: #000000;
}

.temperature-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2px;
  max-width: 250px;
}

.temperature-container-winder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2px;
  max-width: 250px;
  margin: 2px 0px;
}

.temperature-status {
  background-color: #d1d1d1;
}

.ant-table-cell {
  cursor: pointer;
}

/* .idle-container {
  background-color: #FFAD1F !important;
} */

.off-container {
  display: none !important;
}

.spinner {
  filter: saturate(0);
  scale: 2;
}

/* PHONE */
@media only screen and (max-width: 480px) {
  .App {
    height: auto;
    overflow: scroll;
  }

  .grid {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr; */
    height: auto;
    overflow: hidden;
  }

  .App-header {
    height: 8vh;
  }

  .subheader {
    height: auto;
    padding: 5px;
  }

  .statusHeadline {
    color: lightgrey;
    padding: 5px 15px;
    font-size: 10pt;
  }

  .title {
    font-size: 20pt;
  }

  .map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 130vw;
    margin-top: -4vh;
    margin-left: -13vw;
    pointer-events: none;
  }

  .map-base {
    position: absolute;
    top: 0;
    left: 0;
    width: 130vw;
    margin-top: -4vh;
    margin-left: -13vw;
  }

  .mapContainer {
    max-width: 100vw;
    height: auto;
    width: 100%;
    background-color: #c8c8c8;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 200px;
    position: relative;
  }

  .status-scroll {
    max-height: 100%;
    /* 86 viewheight because header is 10vh, subheader is 3vh, and 1vh for gaps/padding */
    overflow-y: visible;
    padding-top: 0px;
  }

  .statusTable {
    margin: 0px;
  }

  .statusTable * {
    font-size: 8pt;
  }

  td:nth-child(odd) {
    font-size: 12pt;
    /* font-weight: 700; */
    text-align: left;
  }

  .App-logo {
    height: 50px;
    margin-right: 0;
  }

  .ant-progress-inner {
    border-radius: 5px !important;
    box-shadow: 3px 5px 5px 2px rgba(144, 144, 144, 0.1);
    border: #595959 1px solid;
  }

  .indc {
    padding: 2px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px;
    width: auto;
    text-align: center;
    box-shadow: 3px 5px 5px 2px rgba(0, 0, 0, 0.2);

    color: rgba(0, 0, 0, 1);
    font-size: 8pt;
    font-weight: 700;
    cursor: pointer;
    border: 2px white solid;
  }

  .indc:hover {
    border: 2px cyan solid;
  }

  .oven-temp-indc {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    margin-top: 8vh;
    margin-left: 12.5vw;
  }
  
  .winder-2-indc {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    margin-top: 1vh;
    margin-left: 3vw;
  }
  
  .winder-humid-indc {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    margin-top: 1vh;
    margin-left: 20vw;
  }
  
  .winder-temp-indc {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    margin-top: 15vh;
    margin-left: 3vw;
  }

  .legend-container {
    display: flex;
    width: 100%;
    margin: auto;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    gap: 10px;
  }

  .legend-item {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 5px;
    align-items: center;
  }

  .legend-color {
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }

  .pb__modal__header h1 {
    width: 70% !important;
  }

  .pb__modal__header {
    justify-content: flex-start !important;
  }

  .pb__modal__close {
    max-width: 50px;
  }

  .pb__modal__body {
    padding: 10px 0px 0px 0px !important;
  }

  .modal-scroll {
    overflow-x: scroll;
    max-width: 100vw;
  }
}

/* PHONE SIDEWAYS*/
/* @media only screen and (orientation: landscape) and (max-width: 480px) {
  .modal-scroll {
    scale: 0.5;
  }
} */