svg {
    width: 100%;
    height: auto;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8 {
    opacity: 1;
    stroke-width: 20px;
    stroke-opacity: 0.2;
}

.cls-9,
.cls-10 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.mapImage g {
    cursor: pointer;
}

.mapImage g:hover {
    filter: brightness(3);
}