.controls-container {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.controls-time {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 10px;
}

.time-text {
    font-size: 10pt;
    color: gray;
}

.controls-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.controls-center Button {
    background-color: transparent;
    border: none;
}

.controls-move svg {
    margin-top: -18px;
    width: 60px;
}

.controls-zoom svg {
    margin-top: -3px;
    width: 30px;
}

.controls-interval svg {
    width: 20px;
}

.controls-interval-main {
    display: flex;
    flex-direction: column;
}

.control-btn {
    background-color: dodgerblue !important;
    padding: 15px 25px;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 16pt;
    border-radius: 15px;
    height: auto !important;
    cursor: pointer;
}

.control-btn:hover {
    background-color: rgb(127, 221, 255) !important;
    color: white !important;
}

.control-today-btn {
    font-size: 12pt;
}

/* PHONE */
@media only screen and (max-width: 480px) {
    .controls-container {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .controls-time {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        gap: 10px;
    }

    .controls-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .controls-center Button {
        background-color: transparent;
        border: none;
    }

    .controls-move svg {
        margin-top: -5px;
        width: 30px;
    }

    .controls-zoom svg {
        margin-top: 0px;
        width: 20px;
    }

    .controls-interval svg {
        width: 20px;
    }

    .controls-interval-main {
        display: flex;
        flex-direction: column;
    }
}